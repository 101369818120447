<template>
  <section class="notifications__table">
    <div class="notifications__table-heading">
      <p class="notifications__table_th-number fira-sans">№</p>
      <p class="notifications__table_th-date fira-sans">Дата окончания</p>
      <p class="notifications__table_th-description fira-sans">Описание</p>
      <p class="notifications__table_th-status fira-sans">Статус</p>
    </div>
    <NotificationsTableItem
      v-for="(notification, index) in notifications"
      :key="notification.id"
      :notification="notification"
      :notificationItem="index + 1"
      @onView="onView"
    />
    <div class="mt-24">
      <ShowMoreButton
        classButton="text-start"
        :currentPage="currentPage"
        :pageCount="pageCount"
        :loadingMore="loadingMore"
        :isShowMoreVisible="isShowMoreVisible"
        @onClick="$emit('showMore')"
        >Показать еще +</ShowMoreButton
      >
    </div>
  </section>
</template>

<script>
  import ShowMoreButton from '../../../common/components/redesigned/ShowMoreButton.vue';
  import NotificationsTableItem from './NotificationsTableItem.vue';

  export default {
    components: { NotificationsTableItem, ShowMoreButton },
    emits: ['showMore', 'onView'],
    props: {
      loadingMore: Boolean,
      currentPage: Number,
      pageCount: Number,
      isShowMoreVisible: {
        type: Boolean,
        default: true,
      },
      notifications: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      onView(notification) {
        this.$emit('onView', notification);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/notificationsTable.scss';
</style>
