<template>
  <button type="button" class="btn-primary btn-status" :class="{ visible: visible }" disabled>
    {{ visible ? 'Просмотрено' : 'Не просмотрено' }}
  </button>
</template>

<script>
  export default {
    components: {},
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn-status {
    width: 100%;
    padding: 4px 0;
    border-radius: 4px;
    background: $gray-silver-dark;
    font: $fira-12;
    cursor: auto;
  }

  .visible {
    background: #25c05a;
  }
</style>
