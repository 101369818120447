<template>
  <div class="container-page container-page-rktv">
    <div class="notifications__title-container">
      <h2 class="notifications__title title-inner title">Уведомления</h2>
      <p class="notifications__number title-inner title" v-if="!loading">
        {{ notViewedNotificationLength }}
      </p>
    </div>
    <Loader v-if="loading" class="notifications__loader" />
    <NotificationsTable
      v-else
      :currentPage="currentPage"
      :pageCount="pageCount"
      :loadingMore="loadingMore"
      :isShowMoreVisible="isShowMoreVisible"
      :notifications="slicedNotifications"
      @showMore="showMore"
      @onView="handleView"
    />
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  import Loader from '@/components/Loader.vue';

  import * as notificationsApi from '../api/notificationsApi';
  import NotificationsTable from '../components/NotificationsTable.vue';

  export default {
    name: 'NotificationsPage',
    components: {
      NotificationsTable,

      Loader,
    },
    data() {
      return {
        loading: false,
        currentPage: 1,
        pageSize: 10,
        loadingMore: false,
        isShowMoreVisible: true,
        notifications: [],
      };
    },

    computed: {
      pageCount() {
        return Math.ceil((this.notifications?.length ?? 0) / this.pageSize);
      },

      notViewedNotificationLength() {
        return (this.notifications ?? []).filter((item) => !item.isViewed).length;
      },

      slicedNotifications() {
        return this.notifications.slice(0, this.currentPage * this.pageSize);
      },

      notificationAdapter() {
        const role = this.$store.state?.auth?.oidc?.user?.profile?.role;

        if (role === Constants.userRoleNames.foreignEmployeeRole) {
          return {
            notificationsApi: notificationsApi.assignmentNotificationAPI,
            callback: (notification) => {
              this.$router.push({
                name: 'EapAssignmentCard',
                params: {
                  id: notification.entityId,
                  action: 'edit'
                },
              });
            }
          }
        }

        return {
          notificationsApi: notificationsApi.measureNotificationAPI,
          callback: (notification) => {
            this.$router.push({
              name: 'RestrictionMeasureEditPage',
              params: {
                id: notification.entityId,
                action: 'edit'
              },
            });
          }
        }
      }
    },

    mounted() {
      this.fetchNotifications();
    },

    methods: {
      showMore() {
        this.loadingMore = true;
        this.currentPage += 1;
        this.loadingMore = false;
      },

      async fetchNotifications() {
        try {
          this.loading = true;
          const notificationResolve = await this.notificationAdapter.notificationsApi.getNotifications();
          if (!notificationResolve.data) {
            throw new Error('Ошибка получения данных');
          }
          this.notifications = notificationResolve.data;
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
          this.loadingMore = false;
        }
      },
      async handleView(notification) {
        try {
          await this.notificationAdapter.notificationsApi.setViewNotification(notification.id);
          this.notificationAdapter.callback.call(this, notification);
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles//notificationsPage.scss';
  @import '@/assets/theme/default/title.scss';
</style>
