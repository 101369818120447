<template>
  <div class="notifications__table-item">
    <p class="notifications__table_td-number fira-sans">
      {{ notificationItem }}
    </p>
    <p class="notifications__table_td-date fira-sans">{{ notificationDate }}</p>
    <div class="notifications__table_td-description" @click="onView">
      <p class="notifications__table_td-description-title">
        {{ notification.title }}
      </p>
      <p class="notifications__table_td-description-subtitle">
        {{ notification.body }}
      </p>
    </div>
    <div class="notifications__table_td-status fira-sans">
      <StatusButton :visible="notification.isViewed" />
    </div>
  </div>
</template>

<script>
  import StatusButton from './StatusButton.vue';

  export default {
    components: { StatusButton },
    emits: ['onView'],
    props: {
      notification: {
        type: Object,
        required: true,
      },
      notificationItem: Number,
    },
    computed: {
      notificationDate() {
        return this.$momentFormat(this.notification.endDate, 'DD.MM.YYYY');
      },
    },
    methods: {
      onView() {
        this.$emit('onView', this.notification);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/notificationsTable.scss';
</style>
